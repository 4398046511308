<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Redefinir senha</h4>
                                    <p>por favor digite seu endereço de e-mail e enviaremos instruções sobre como redefinir sua senha.</p>
                                </div>
                                <vs-input
                                    v-validate="'required|email|min:3'"
                                    name="email"
                                    data-vv-as="E-mail"
                                    icon-no-border
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label-placeholder="E-mail"
                                    v-model="email"
                                    class="w-full" />
                                <p class="text-danger text-sm">{{ errors.first('email') }}</p>
                                <vs-button type="border" to="/login" class="px-4 w-full md:w-auto mt-8">Voltar ao login</vs-button>
                                <vs-button @click="sendEmail" :disabled="!validateForm" class="float-right px-4 w-full md:w-auto mt-8 mb-8 md:mt-8 md:mb-0">Redefinir senha</vs-button>
                                <vs-alert :active.sync="alert" class="mt-5" icon-pack="feather" icon="icon-info" closable close-icon="icon-x">
                                  <span>Enviamos o link de redefinição de senha para seu e-mail! <br> Verifique e siga as instruções.</span>
                                </vs-alert>
                                <vs-alert :active.sync="errorAlert" class="mt-5" color="danger" icon-pack="feather" icon="icon-info" closable close-icon="icon-x">
                                  <span>{{ errorAlertMessage }}</span>
                                </vs-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
import axios from '@/axios.js'

export default {
  data () {
    return {
      email: '',
      alert: false,
      errorAlert: false,
      errorAlertMessage: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== ''
    }
  },
  methods: {
    async sendEmail () {
      this.$vs.loading()
      try {
        const resp = await axios.post(`${process.env.VUE_APP_API_URL}/password/email`, { email: this.email })
        this.errorAlert = false
        this.alert = true
        this.email = '' //evita duplo clique
        this.$vs.loading.close()
        console.log(resp)
      } catch (error) {
        this.$vs.loading.close()
        this.alert = false
        this.errorAlert = true
        this.errorAlertMessage = error.response.data.error
      }
    }
  }
}
</script>
