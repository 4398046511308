var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/forgot-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-8" },
                      [
                        _c("div", { staticClass: "vx-card__title mb-8" }, [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v("Redefinir senha"),
                          ]),
                          _c("p", [
                            _vm._v(
                              "por favor digite seu endereço de e-mail e enviaremos instruções sobre como redefinir sua senha."
                            ),
                          ]),
                        ]),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email|min:3",
                              expression: "'required|email|min:3'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            name: "email",
                            "data-vv-as": "E-mail",
                            "icon-no-border": "",
                            icon: "icon icon-user",
                            "icon-pack": "feather",
                            "label-placeholder": "E-mail",
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                        _c("p", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("email"))),
                        ]),
                        _c(
                          "vs-button",
                          {
                            staticClass: "px-4 w-full md:w-auto mt-8",
                            attrs: { type: "border", to: "/login" },
                          },
                          [_vm._v("Voltar ao login")]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass:
                              "float-right px-4 w-full md:w-auto mt-8 mb-8 md:mt-8 md:mb-0",
                            attrs: { disabled: !_vm.validateForm },
                            on: { click: _vm.sendEmail },
                          },
                          [_vm._v("Redefinir senha")]
                        ),
                        _c(
                          "vs-alert",
                          {
                            staticClass: "mt-5",
                            attrs: {
                              active: _vm.alert,
                              "icon-pack": "feather",
                              icon: "icon-info",
                              closable: "",
                              "close-icon": "icon-x",
                            },
                            on: {
                              "update:active": function ($event) {
                                _vm.alert = $event
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Enviamos o link de redefinição de senha para seu e-mail! "
                              ),
                              _c("br"),
                              _vm._v(" Verifique e siga as instruções."),
                            ]),
                          ]
                        ),
                        _c(
                          "vs-alert",
                          {
                            staticClass: "mt-5",
                            attrs: {
                              active: _vm.errorAlert,
                              color: "danger",
                              "icon-pack": "feather",
                              icon: "icon-info",
                              closable: "",
                              "close-icon": "icon-x",
                            },
                            on: {
                              "update:active": function ($event) {
                                _vm.errorAlert = $event
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.errorAlertMessage))])]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }